"use client";

import { Button } from "components/Elements/Button/Button";
import { redirect, usePathname, useRouter } from "next/navigation";
import { useState } from "react";
import { hashs } from "utils/hasher";

export default function PromptPw({
  hashAction,
}: { hashAction: (path: string, pw: string) => void }) {
  const [pass, setPass] = useState("");
  const path = usePathname();

  return (
    <div className="flex flex-row gap-3">
      <input
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            hashAction(path, pass);
          }
        }}
        onChange={(x) => setPass(x.currentTarget.value)}
        type="password"
      />
      <button
        onClick={() => {
          hashAction(`${window.location.origin}${path}`, pass);
        }}
        className="bg-black text-white px-3 py-1"
        type="submit"
      >
        submit
      </button>
    </div>
  );
}
