"use client";
// @ts-ignore
export function OptionsCss({ optionsData }) {
  return <style jsx global>
    {`
          @media (min-width: 768px) {
            h1 {
              font-size: ${optionsData.acf.h1_desktop}!important;
            }

            h2 {
              font-size: ${optionsData.acf.h2_desktop}!important;
            }

            h3 {
              font-size: ${optionsData.acf.h3_desktop}!important;
            }

            h4 {
              font-size: ${optionsData.acf.h4_desktop}!important;
            }

            h5 {
              font-size: ${optionsData.acf.h5_desktop}!important;
            }

            h6 {
              font-size: ${optionsData.acf.h6_desktop}!important;
            }
          }

          h1 {
            font-size: ${optionsData.acf.h1_mobile}!important;
            font-weight: 600;
            line-height: 1.1;
            text-align: left;
          }

          h2 {
            font-size: ${optionsData.acf.h2_mobile}!important;
            font-weight: 600;
            line-height: 1.1;
            text-align: left;
          }

          h3 {
            font-size: ${optionsData.acf.h3_mobile}!important;
            font-weight: 600;
            line-height: 1.1;
            text-align: left;
          }

          h4 {
            font-size: ${optionsData.acf.h4_mobile}!important;
            font-weight: 600;
            line-height: 1.1;
            text-align: left;
          }

          h5 {
            font-size: ${optionsData.acf.h5_mobile}!important;
            font-weight: 600;
            line-height: 1.1;
            text-align: left;
          }

          h6 {
            font-size: ${optionsData.acf.h6_mobile}!important;
            font-weight: 600;
            line-height: 1.1;
            text-align: left;
          }
        `}
  </style>
}

